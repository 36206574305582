/* 
Nectaria - FREE HTML TEMPLATE BY WOWTHEMES.NET
Version: 1.0.0
Download Link: https://www.wowthemes.net/marketplace/category/free/
*/

#root {
	background-color: #0075A9;
}

body {
	font-family:  Roboto;
	overflow-x:   hidden;
	background: #0075A9;
}

.ant-modal-content {
	border-radius: 20px;
	/* background: red; */
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {
	display:block;
}
audio,canvas,video {
	display:inline-block;
}
audio:not([controls]) {
	display:none;
	height:0;
}
[hidden],template {
	display:none;
}
html {
	font-family:sans-serif;
	-ms-text-size-adjust:100%;
	-webkit-text-size-adjust:100%;
}
body {
	margin:0;
}
a {
	background:transparent;
}
a:focus {
	outline:0;
}
a:active,a:hover {
	outline:0;
}
h1 {
	font-size:2em;
	margin:0.67em 0;
}
abbr[title] {
	border-bottom:1px dotted;
}
b,strong {
	font-weight:700;
}
dfn {
	font-style:italic;
}
hr {
	-moz-box-sizing:content-box;
	box-sizing:content-box;
	height:0;
}
mark {
	background:#FF0;
	color:#000;
}
code,kbd,pre,samp {
	font-family:monospace,serif;
	font-size:1em;
}
pre {
	white-space:pre-wrap;
}
q {
	quotes:"\201C" "\201D" "\2018" "\2019";
}
small {
	font-size:80%;
}
sub,sup {
	font-size:75%;
	line-height:0;
	position:relative;
	vertical-align:baseline;
}
sup {
	top:-0.5em;
}
sub {
	bottom:-0.25em;
}
img {
	border:0;
	max-width:100%;
}
svg:not(:root) {
	overflow:hidden;
}
figure {
	margin:0;
}
fieldset {
	border:1px solid #c0c0c0;
	margin:0 2px;
	padding:0.35em 0.625em 0.75em;
}
legend {
	border:0;
	padding:0;
}
button,input,select,textarea {
	font-family:inherit;
	font-size:100%;
	margin:0;
}
button,input {
	line-height:normal;
}
button,select {
	text-transform:none;
}
button,html input[type="button"],input[type="reset"],input[type="submit"] {
	-webkit-appearance:button;
	cursor:pointer;
}
button[disabled],html input[disabled] {
	cursor:default;
}
input[type="checkbox"],input[type="radio"] {
	box-sizing:border-box;
	padding:0;
}
input[type="search"] {
	-webkit-appearance:textfield;
	-moz-box-sizing:content-box;
	-webkit-box-sizing:content-box;
	box-sizing:content-box;
}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
	-webkit-appearance:none;
}
button::-moz-focus-inner,input::-moz-focus-inner {
	border:0;
	padding:0;
}
textarea {
	overflow:auto;
	vertical-align:top;
}
table {
	border-collapse:collapse;
	border-spacing: 0;
}
/* ========================================================================== 1. Icons - Sets up the icon font and respective classes ========================================================================== */
/* Import the font file with the icons in it */
@font-face {
	font-family:"casper-icons";
	src:url("./assets/fonts/casper-icons.eot?v=1");
	src:url("./assets/fonts/casper-icons.eot?v=1#iefix") format("embedded-opentype"),url("./assets/fonts/casper-icons.woff?v=1") format("woff"),url("./assets/fonts/casper-icons.ttf?v=1") format("truetype"),url("./assets/fonts/casper-icons.svg?v=1#icons") format("svg");
	font-weight:normal;
	font-style: normal;
}
/* Apply these base styles to all icons */
[class^="icon-"]:before,[class*=" icon-"]:before {
	font-family:"casper-icons","Open Sans",sans-serif;
	speak:none;
	font-style:normal;
	font-weight:normal;
	font-variant:normal;
	text-transform:none;
	line-height:1;
	text-decoration:none !important;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Each icon is created by inserting the correct character into the content of the:before pseudo element. Like a boss. */
.icon-ghost:before {
	content:"\f600";
}
.icon-feed:before {
	content:"\f601";
}
.icon-twitter:before {
	content:"\f602";
	font-size:1.1em;
}
.icon-google-plus:before {
	content:"\f603";
}
.icon-facebook:before {
	content:"\f604";
}
.icon-arrow-left:before {
	content:"\f605";
}
.icon-stats:before {
	content:"\f606";
}
.icon-location:before {
	content:"\f607";
	margin-left:-3px;
	/* Tracking fix */
}
.icon-link:before {
	content:"\f608";
}
.icon-menu:before {
	content: "\f609";
}
/* IMPORTANT:When making any changes to the icon font,be sure to increment the version number by 1 in the @font-face rule. `?v=1` becomes `?v=2` This forces browsers to download the new font file.*/
/* ========================================================================== 2. General - Setting up some base styles ========================================================================== */
html {
	height:100%;
	max-height:100%;
	font-size:62.5%;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
body {
	height:100%;
	max-height:100%;
	letter-spacing:0.01rem;
	font-size:1.7rem;
	line-height:1.75em;
	color:#333;
	-webkit-font-feature-settings:'kern' 1;
	-moz-font-feature-settings:'kern' 1;
	-o-font-feature-settings:'kern' 1;
	text-rendering:geometricPrecision;
	font-weight:300;
}
::-moz-selection {
	background:#D6EDFF;
}
::selection {
	background:#D6EDFF;
}
h1,h2,h3,h4,h5,h6 {
	-webkit-font-feature-settings:'dlig' 1,'liga' 1,'lnum' 1,'kern' 1;
	-moz-font-feature-settings:'dlig' 1,'liga' 1,'lnum' 1,'kern' 1;
	-o-font-feature-settings:'dlig' 1,'liga' 1,'lnum' 1,'kern' 1;
	color:#2E2E2E;
	line-height:1.15em;
	margin:0 0 0.4em 0;
	font-family:"Playfair Display";
	text-rendering:geometricPrecision;
}
h1 {
	font-size:5rem;
	letter-spacing:-2px;
	text-indent:-3px;
}
h2 {
	font-size:3.6rem;
}
h3 {
	font-size:3rem;
}
h4 {
	font-size:2.5rem;
}
h5 {
	font-size:2rem;
}
h6 {
	font-size:2rem;
}
a {
	color:#4A4A4A;
	transition:color 0.3s ease;
}
a:hover {
	color:#111;
}
p,ul,ol,dl {
	-webkit-font-feature-settings:'liga' 1,'onum' 1,'kern' 1;
	-moz-font-feature-settings:'liga' 1,'onum' 1,'kern' 1;
	-o-font-feature-settings:'liga' 1,'onum' 1,'kern' 1;
	margin:0 0 1.75em 0;
	text-rendering:geometricPrecision;
}
ol,ul {
	padding-left:3rem;
}
ol ol,ul ul,ul ol,ol ul {
	margin:0 0 0.4em 0;
	padding-left:2em;
}
dl dt {
	float:left;
	width:180px;
	overflow:hidden;
	clear:left;
	text-align:right;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-weight:700;
	margin-bottom:1em;
}
dl dd {
	margin-left:200px;
	margin-bottom:1em
}
li {
	margin:0.4em 0;
}
li li {
	margin:0;
}
hr {
	display:block;
	height:1px;
	border:0;
	border-top:#EFEFEF 1px solid;
	margin:3.2em 0;
	padding:0;
}
blockquote {
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	margin:1.75em 0 1.75em -2.2em;
	padding:0 0 0 1.75em;
	border-left:#4A4A4A 0.4em solid;
}
blockquote p {
	margin:0.8em 0;
	font-style:italic;
}
blockquote small {
	display:inline-block;
	margin:0.8em 0 0.8em 1.5em;
	font-size:0.9em;
	color:#CCC;
}
blockquote small:before {
	content:"\2014 \00A0";
}

.organic-arrow-color {
	color:red;
}

blockquote cite {
	font-weight:700;
}
blockquote cite a {
	font-weight:normal;
}
mark {
	background-color:#fdffb6;
}
code,tt {
	padding:1px 3px;
	font-family:Inconsolata,monospace,sans-serif;
	font-size:0.85em;
	white-space:pre-wrap;
	border:#E3EDF3 1px solid;
	background:#F7FAFB;
	border-radius:2px;
}
pre {
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	margin:0 0 1.75em 0;
	border:#E3EDF3 1px solid;
	width:100%;
	padding:10px;
	font-family:Inconsolata,monospace,sans-serif;
	font-size:0.9em;
	white-space:pre;
	overflow:auto;
	background:#F7FAFB;
	border-radius:3px;
}
pre code,pre tt {
	font-size:inherit;
	white-space:pre-wrap;
	background:transparent;
	border:none;
	padding:0;
}
kbd {
	display:inline-block;
	margin-bottom:0.4em;
	padding:1px 8px;
	border:#CCC 1px solid;
	color:#666;
	text-shadow:#FFF 0 1px 0;
	font-size:0.9em;
	font-weight:700;
	background:#F4F4F4;
	border-radius:4px;
	box-shadow:0 1px 0 rgba(0,0,0,0.2),0 1px 0 0 #fff inset;
}
table {
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	margin:1.75em 0;
	width:100%;
	max-width:100%;
	background-color:transparent;
}
table th,table td {
	padding:8px;
	line-height:20px;
	text-align:left;
	vertical-align:top;
	border-top:#EFEFEF 1px solid;
}
table th {
	color:#000;
}
table caption+thead tr:first-child th,table caption+thead tr:first-child td,table colgroup+thead tr:first-child th,table colgroup+thead tr:first-child td,table thead:first-child tr:first-child th,table thead:first-child tr:first-child td {
	border-top:0;
}
table tbody+tbody {
	border-top:#EFEFEF 2px solid;
}
table table table {
	background-color:#FFF;
}
table tbody>tr:nth-child(odd)>td,table tbody>tr:nth-child(odd)>th {
	background-color:#F6F6F6;
}
table.plain tbody>tr:nth-child(odd)>td,table.plain tbody>tr:nth-child(odd)>th {
	background:transparent;
}
iframe,.fluid-width-video-wrapper {
	display:block;
	margin: 1.75em 0;
}
/* When a video is inside the fitvids wrapper,drop themargin on the iframe,cause it breaks stuff. */
.fluid-width-video-wrapper iframe {
	margin: 0;
}
/* ========================================================================== 3. Utilities - These things get used a lot ========================================================================== */
/* Clears shit */
.clearfix:before,.clearfix:after {
	content:" ";
	display:table;
}
.clearfix:after {
	clear:both;
}
.clearfix {
	zoom: 1;
}
/* Hides shit */
.hidden {
	text-indent:-9999px;
	visibility:hidden;
	display: none;
}
/* Creates a responsive wrapper that makes our content scale nicely */
.inner {
	position:relative;
	width:80%;
	max-width:710px;
	margin: 0 auto;
}
/* Centres vertically yo. (IE8+) */
.vertical {
	/* display: flex; */
	/* align-items: center;
	justify-content: center; */
	/* vertical-align:middle; */
	height: 100%;
	 background: #0075A9;
}

.verticaldetail {
	display:table-cell;
	vertical-align:middle;
	 background: rgba(0,0,0,0.2);
}
/* Wraps the main content & footer */
.site-wrapper {
	position:relative;
	z-index:10;
	min-height:100%;
	background:#fff;
	-webkit-transition:-webkit-transform 0.5s ease;
	transition:transform 0.5s ease;
	margin-top:64px;
}
.home-template .site-wrapper {
}
body.nav-opened .site-wrapper {
	overflow-x:hidden;
	-webkit-transform:translate3D(-240px,0,0);
	-ms-transform:translate3D(-240px,0,0);
	transform:translate3D(-240px,0,0);
	-webkit-transition:-webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
}
/* ========================================================================== 4. General - The main styles for the the theme ========================================================================== */
.content {
	width:85%;
	margin:0px auto;
	background:#fff;
	padding:40px 10px;
	margin-top:-100px;
	position:relative;
	border-left:1px solid rgba(0,0,0,0.08);
	border-right:1px solid rgba(0,0,0,0.08);
	border-top: 1px solid rgba(0,0,0,0.08);
}
/* Big cover image on the home page */
.main-header {
	position:relative;
	display:table;
	width:100%;
	height:100vh;
	margin-bottom:5rem;
	text-align:center;
	background:#222 no-repeat center center;
	/* background-image:url(./assets/img/default.jpg); */
	background-size:cover;
	overflow:hidden;
}
.main-header .inner {
	width:80%;
	margin-top:-64px;
}
.main-header-content.inner h1,.main-header-content.inner .post-meta,.main-header-content.inner .post-meta a {
	color:#fff;
}
.main-nav {
	position:relative;
	padding:5px 5%;
	margin:0 0 0px 0;
}
.main-nav a {
	text-decoration:none;
	font-size:0.9em;
	letter-spacing:0.3px;
	font-weight:400;
	color:#ccc;
	font-family:Playfair Display;
}
.main-nav .nav-current a {
	color:#fff;
}
.main-nav a:hover {
	color: #fff;
}
/* Navigation */
body.nav-opened .nav-cover {
	position:fixed;
	top:0;
	left:0;
	right:240px;
	bottom:0;
	z-index:200;
}
.nav {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	z-index:5;
	width:240px;
	opacity:0;
	background:#111;
	margin-bottom:0;
	text-align:left;
	overflow-y:auto;
	-webkit-transition:-webkit-transform 0.5s ease,opacity 0.3s ease 0.7s;
	transition:transform 0.5s ease,opacity 0.3s ease 0.7s;
}
body.nav-closed .nav {
	-webkit-transform:translate3D(97px,0,0);
	-ms-transform:translate3D(97px,0,0);
	transform:translate3D(97px,0,0);
}
body.nav-opened .nav {
	opacity:1;
	-webkit-transition:-webkit-transform 0.3s ease,opacity 0s ease 0s;
	transition:transform 0.3s ease,opacity 0s ease 0s;
	-webkit-transform:translate3D(0,0,0);
	-ms-transform:translate3D(0,0,0);
	transform:translate3D(0,0,0);
}
.nav-title {
	position:absolute;
	top:45px;
	left:30px;
	font-size:16px;
	font-weight:100;
	text-transform:uppercase;
	color:#fff;
}
.nav-close {
	position:absolute;
	top:38px;
	right:25px;
	width:20px;
	height:20px;
	padding:0;
	font-size:10px;
}
.nav-close:focus {
	outline:0;
}
.nav-close:before,.nav-close:after {
	content:'';
	position:absolute;
	top:0;
	width:20px;
	height:1px;
	background:rgb(150,150,150);
	top:15px;
	-webkit-transition:background 0.15s ease;
	transition:background 0.15s ease;
}
.nav-close:before {
	-webkit-transform:rotate(45deg);
	-ms-transform:rotate(45deg);
	transform:rotate(45deg);
}
.nav-close:after {
	-webkit-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.nav-close:hover:before,.nav-close:hover:after {
	background:rgb(255,255,255);
}
.nav ul {
	padding:90px 9% 5%;
	list-style:none;
	counter-reset:item;
}
.nav li:before {
	display:block;
	float:right;
	padding-right:4%;
	padding-left:5px;
	text-align:right;
	font-size:1.2rem;
	vertical-align:bottom;
	color:#B8B8B8;
	content:counter(item,lower-roman);
	counter-increment:item;
}
.nav li {
	margin:0;
}
.nav li a {
	text-decoration:none;
	line-height:1.4;
	font-size:1.4rem;
	display:block;
	padding:0.6rem 4%;
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
}
.nav li a:after {
	display:inline-block;
	content:" .......................................................";
	color:rgba(255,255,255,0.2);
	margin-left:5px;
}
.nav .nav-current:before {
	color:#fff;
}
.nav .nav-current a:after {
	content:" ";
	border-bottom:rgba(255,255,255,0.5) 1px solid;
	width:100%;
	height:1px;
}
.nav a:link,.nav a:visited {
	color:#B8B8B8;
}
.nav li.nav-current a,.nav a:hover,.nav a:active,.nav a:focus {
	color: #fff;
}
.subscribe-button {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	display:none;
	position:absolute;
	bottom:30px;
	left:30px;
	right:30px;
	height:38px;
	padding:0 20px;
	color:#111 !important;
	/* Overides `.nav a:link,.nav a:visited` colour */
	text-align:center;
	font-size:12px;
	font-family:"Open Sans",sans-serif;
	text-transform:uppercase;
	text-decoration:none;
	line-height:35px;
	border-radius:3px;
	background:#fff;
	transition:all ease 0.3s;
}
.subscribe-button:before {
	font-size:9px;
	margin-right: 6px;
}
/* Create a bouncing scroll-down arrow on homepage with cover image */
.scroll-down {
	display:block;
	position:absolute;
	z-index:100;
	bottom:75px;
	left:50%;
	margin-left:-16px;
	width:34px;
	height:34px;
	font-size:34px;
	text-align:center;
	text-decoration:none;
	color:rgba(255,255,255,0.7);
	-webkit-transform:rotate(-90deg);
	-ms-transform:rotate(-90deg);
	transform:rotate(-90deg);
	-webkit-animation:bounce 4s 2s infinite;
	animation: bounce 4s 2s infinite;
}
/* Stop it bouncing and increase contrast when hovered */
.scroll-down:hover {
	color:#fff;
	-webkit-animation:none;
	animation: none;
}
/* Put a semi-opaque radial gradient behind the icon to make it more visible on photos which happen to have a light background. */
.home-template .main-header:after {
	display:block;
	content:" ";
	width:150px;
	height:130px;
	border-radius:100%;
	position:absolute;
	bottom:0;
	left:50%;
	margin-left:-75px;
	background: radial-gradient(ellipse at center,rgba(0,0,0,0.15) 0%,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
}
/* Hide when there's no cover image or on page2+*/
.no-cover .scroll-down,.no-cover.main-header:after,.archive-template .scroll-down,.archive-template .main-header:after {
	display: none
}
/* Appears in the top left corner of your home page */
.blog-logo {
	display:block;
	float:left;
	background:none !important;
	/* Makes sure there is never a background */
	border:none !important;
	/* Makes sure there is never a border */
}
.blog-logo img {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	display:block;
	height:auto;
	padding:0;
	width:auto;
	opacity:1;
	transition:all 0.4s;
}
.blog-logo img:hover {
	opacity:1.0;
	transition:all 0.4s;
}
.menu-button {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	display:inline-block;
	float:right;
	padding:0;
	opacity:1;
	text-align:center;
	font-size:12px;
	text-transform:uppercase;
	white-space:nowrap;
	transition:all 0.5s ease;
}
.menu-button:before {
	font-size:24px;
	font-weight:300;
	position:relative;
	top:14px;
}
.menu-button:hover {
	background:#fff;
}
.menu-button:focus {
	outline: 0;
}
/* When the navigation is closed */
.nav-closed .menu-button {
	color:#333;
	border-color:rgba(255,255,255,0.6);
}
.nav-closed .menu-button:hover {
	color: #222;
}
/* When the navigation is closed and there is no cover image */
.nav-closed .no-cover .menu-button {
	border-color:#BFC8CD;
	color:#9EABB3;
}
.nav-closed .no-cover .menu-button:hover {
	border-color:#555;
	color: #555;
}
/* When the navigation is opened */
.nav-opened .menu-button {
	padding:0 12px;
	background:#111;
	border-color:#111;
	color:#fff;
	-webkit-transform:translate3D(94px,0,0);
	-ms-transform:translate3D(94px,0,0);
	transform:translate3D(94px,0,0);
	transition:all 0.3s ease;
}
.nav-opened .menu-button .word {
	opacity:0;
	transition: all 0.3s ease;
}
/* Special styles when overlaid on an image*/
.main-nav.overlay {
	position:fixed;
	top:0;
	left:0;
	right:0;
	height:54px;
	border:none;
	background:linear-gradient(to bottom,rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%);
	background:rgba(0,0,0,0.8);
	z-index:9;
}
.no-cover .main-nav.overlay {
	background: none;
}
/* The details of your blog. Defined in ghost/settings/ */
.page-title {
	margin:10px 0 0px 0;
	font-size:6.5rem;
	letter-spacing:-1px;
	font-weight:700;
	font-family:"Playfair Display";
	color:#fff;
	font-style:italic;
}
.page-description {
	margin:0;
	font-size:2rem;
	line-height:1.5em;
	font-weight:300;
	font-style:italic;
	letter-spacing:0.01rem;
	color:rgba(255,255,255,0.9);
}
.no-cover.main-header {
	min-height:160px;
	max-height:40vh;
	background:#f5f8fa;
}
.no-cover .page-title {
	color:rgba(0,0,0,0.8);
}
.no-cover .page-description {
	color: rgba(0,0,0,0.5);
}
/* Add subtle load-in animation for content on the home page */
.home-template .page-title {
	-webkit-animation:fade-in 0.6s;
	animation:fade-in 0.6s;
	-webkit-animation-delay:0.2s;
	animation-delay:0.2s;
}
.home-template .page-description {
	-webkit-animation:fade-in-down 0.9s;
	animation:fade-in-down 0.9s;
	-webkit-animation-delay:0.1s;
	animation-delay: 0.1s;
}
/* Every post,on every page,gets this style on its <article>tag */
.post {
	position:relative;
	border-bottom:#EBF2F6 1px solid;
		padding-bottom:30px;
	word-wrap:break-word;
}
.grid-item .post {
	position:relative;
	border:#EBF2F6 1px solid;
	word-wrap:break-word;
		margin-bottom:20px;
	 padding-bottom:0;
	 max-width:100%;
	 transition:all 0.4s;
	 background:#fff;
	overflow:hidden;
}
.grid-item .post img {
	margin-bottom:-10px;
}
.wrapgriditem {
	padding:30px;
	position:relative;
	background-color:#fff;
}
.grid-item .post.featured .wrapgriditem {
	background:#f7faf9;
	box-shadow: none;
}
/* Add a little circle in the middle of the border-bottom on our .post just for the lolz and stylepoints. */
.post:after {
	display:block;
	content:"";
	width:0px;
	height:0px;
	border:#E7EEF2 1px solid;
	position:absolute;
	bottom:-5px;
	left:50%;
	margin-left:-5px;
	background:#FFF;
	border-radius:100%;
	box-shadow:#FFF 0 0 0 5px;
}
body:not(.post-template) .post-title {
	font-size:2.5rem;
	font-style:italic;
	line-height:1.2;
}
.post-title a {
	text-decoration:none;
}
.post-excerpt p {
	margin:0;
	font-size:0.9em;
	line-height:1.6em;
}
.read-more {
	text-decoration:none;
}
.post-meta {
	display:block;
	margin:1.75rem 0 0 0;
	font-size:1.6rem;
	font-style:italic;
	line-height:2.2rem;
	color:#9EABB3;
	font-family:Playfair Display;
}
.author-thumb {
	width:24px;
	height:24px;
	float:left;
	margin-right:9px;
	border-radius:100%;
}
.post-meta a {
	color:#9EABB3;
	text-decoration:none;
}
.post-meta a:hover {
	text-decoration:underline;
}
.user-meta {
	position:relative;
	padding:0.3rem 40px 0 100px;
	min-height:77px;
}
.post-date {
	display:inline-block;
	margin-left:8px;
	padding-left:12px;
	border-left:#d5dbde 1px solid;
	white-space:nowrap;
}
.grid-item .post-meta,.grid-item .post-date {
	font-size:1.4rem;
}
.user-image {
	position:absolute;
	top:0;
	left:0;
}
.user-name {
	display:block;
	font-weight:700;
}
.user-bio {
	display:block;
	max-width:440px;
	font-size:1.4rem;
	line-height:1.5em;
}
.publish-meta {
	position:absolute;
	top:0;
	right:0;
	padding:4.3rem 0 4rem 0;
	text-align:right;
}
.publish-heading {
	display:block;
	font-weight:700;
}
.publish-date {
	display:block;
	font-size:1.4rem;
	line-height: 1.5em;
}
/* ========================================================================== 5. Single Post - When you click on an individual post ========================================================================== */
.post-template .post-header {
	margin-bottom:3.4rem;
}
.post-template .post-title {
	margin-bottom:0;
	letter-spacing:-1px;
	font-style:italic;
}
.post-template .post-meta {
	margin:0;
}
.post-template .post-date {
	padding:0;
	margin:0;
	border: none;
}
/* Stop elements,such as img wider than the post content,from creating horizontal scroll - slight hack due to imperfections with browser width % calculations and rounding */
.post-template .content {
	width:80%;
	max-width:900px;
	margin:0px auto;
	margin-top:-100px;
	background:#fff;
	position:relative;
	padding:40px 50px;
	font-weight:300;
	font-family:Roboto;
	overflow:hidden;
}
.site-wrapper {
	background-color:#f7faf9;
}
.sidebar {
	width:25%;
	float:right;
	margin-right:3%;
}
.widget {
	padding:30px;
	background:#f7faf9;
	margin-bottom:30px;
	overflow:hidden;
	font-size:1.5rem;
	font-weight:300;
}
.widget .widget-title {
	text-align:center;
	position:relative;
	text-transform:capitalize;
	margin-bottom:27px;
	margin-top:0;
	clear:both;
}
.widget .widget-title:before {
	background:#ebf1ef;
	content:"";
	position:absolute;
	width:100%;
	height:10px;
	left:0;
	top:50%;
	margin-top:-4px;
}
.widget .widget-title>span {
	position:relative;
	z-index:3;
	display:inline-block;
	padding:0 15px;
	background-color:#f7faf9;
}
h2.widget-title {
	font-size:2rem;
	font-style: italic;
}
/* Tweak the .post wrapper style */
.post-template .post {
	margin-top:0;
	border-bottom:none;
	padding-bottom: 0;
}
/* Kill that stylish little circle that was on the border,too */
.post-template .post:after {
	display: none;
}
/* Keep images centered,and allow images wider than the main text column to break out. */
.post-content img {
	display:block;
	max-width:126%;
	height:auto;
	padding:0.6em 0;
	/* Centers an image by (1) pushing its left edge to the center of its container and (2) shifting the entire image in the opposite direction by half its own width. Works for images that are larger than their containers. */
	position:relative;
	left:50%;
	-webkit-transform:translateX(-50%);
	/* for Safari and iOS */
	-ms-transform:translateX(-50%);
	/* for IE9 */
	transform:translateX(-50%);
}
.footnotes {
	font-style:italic;
	font-size:1.3rem;
	line-height:1.6em;
}
.footnotes li {
	margin:0.6rem 0;
}
.footnotes p {
	margin:0;
}
.footnotes p a:last-child {
	text-decoration: none;
}
/* The author credit area after the post */
.post-footer {
	position:relative;
	margin:6rem 0 0 0;
	padding:6rem 0 0 0;
	border-top:#EBF2F6 1px solid;
}
.post-footer h4 {
	font-size:1.8rem;
	margin:0;
}
.post-footer p {
	margin:1rem 0;
	line-height: 1.75em;
}
/* list of author links - location / url */
.author-meta {
	padding:0;
	margin:0;
	list-style:none;
	font-size:1.4rem;
	line-height:1;
	font-style:italic;
	color:#9EABB3;
}
.author-meta a {
	color:#9EABB3;
}
.author-meta a:hover {
	color: #111;
}
/* Create some space to the right for the share links */
.post-footer .author {
	margin-right:180px;
}
.post-footer h4 a {
	color:#2e2e2e;
	text-decoration:none;
}
.post-footer h4 a:hover {
	text-decoration: underline;
}
/* Drop the share links in the space to the right. Doing it like this means it's easier for the author bio to be flexible at smaller screen sizes while the share links remain at a fixed width the whole time */
.post-footer .share {
	position:absolute;
	top:6rem;
	right:0;
	width:140px;
}
.post-footer .share a {
	font-size:1.8rem;
	display:inline-block;
	margin:1rem 1.6rem 1.6rem 0;
	color:#BBC7CC;
	text-decoration:none;
}
.post-footer .share .icon-twitter:hover {
	color:#55acee;
}
.post-footer .share .icon-facebook:hover {
	color:#3b5998;
}
.post-footer .share .icon-google-plus:hover {
	color: #dd4b39;
}
/* ========================================================================== 6. Author profile ========================================================================== */
.post-head.main-header {
	height:65vh;
	min-height:180px;
}
.no-cover.post-head.main-header {
	height:85px;
	min-height:0;
	margin-bottom:0;
	background:transparent;
}
.tag-head.main-header {
	height:65vh;
	min-height:180px;
}
.author-head.main-header {
	height:40vh;
	min-height:180px;
}
.no-cover.author-head.main-header {
	height:10vh;
	min-height:100px;
	background:transparent;
}
.author-profile {
	padding:0 15px 5rem 15px;
	text-align:center;
	margin-bottom: 5rem;
}
/* Add a little circle in the middle of the border-bottom */
.author-image {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	display:block;
	position:absolute;
	top:-40px;
	left:50%;
	margin-left:-40px;
	width:80px;
	height:80px;
	border-radius:100%;
	overflow:hidden;
	padding:6px;
	background:#fff;
	z-index:2;
	box-shadow:#E7EEF2 0 0 0 1px;
}
.author-image .img {
	position:relative;
	display:block;
	width:100%;
	height:100%;
	background-size:cover;
	background-position:center center;
	border-radius:100%;
}
.author-profile .author-image {
	position:relative;
	left:auto;
	top:auto;
	width:120px;
	height:120px;
	padding:3px;
	margin:-100px auto 0 auto;
	box-shadow:none;
}
.author-title {
	margin:1.5rem 0 1rem;
}
.author-bio {
	font-size:2.1rem;
	line-height:1.5em;
	font-weight:200;
	color:#50585D;
	font-style:italic;
	letter-spacing:0;
	text-indent:0;
}
.author-meta {
	margin: 1.6rem 0;
}
/* Location,website,and link */
.author-profile .author-meta {
	margin:2rem 0;
	font-family:Playfair Display;
	letter-spacing:0.01rem;
	font-size:1.7rem;
}
.author-meta span {
	display:inline-block;
	margin:0 2rem 1rem 0;
	word-wrap:break-word;
}
.author-meta a {
	text-decoration: none;
}
/* Turn off meta for page2+to make room for extra pagination prev/next links */
.archive-template .author-profile .author-meta {
	display: none;
}
/* ========================================================================== 7. Read More - Next/Prev Post Links ========================================================================== */
.read-next {
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:stretch;
	-webkit-align-items:stretch;
	-ms-flex-align:stretch;
	align-items:stretch;
	margin-top:0rem;
}
.read-next-story {
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	-ms-flex-positive:1;
	flex-grow:1;
	min-width:50%;
	text-decoration:none;
	position:relative;
	text-align:center;
	color:#fff;
	background:#222 no-repeat center center;
	background-size:cover;
	overflow:hidden;
}
.read-next-story:hover:before {
	background:rgba(0,0,0,0.8);
	transition:all 0.2s ease;
}
.read-next-story:hover .post:before {
	color:#222;
	background:#fff;
	transition:all 0.2s ease;
}
.read-next-story:before {
	content:"";
	display:block;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(0,0,0,0.7);
	transition:all 0.5s ease;
}
.read-next-story .post {
	padding-top:6rem;
	padding-bottom:6rem;
	max-width:80%;
	margin:0px auto;
}
.read-next-story .post:before {
	content:"Read This Next";
	padding:4px 10px 5px;
	text-transform:uppercase;
	font-size:1.1rem;
	font-family:"Open Sans",sans-serif;
	color:rgba(255,255,255,0.8);
	border:rgba(255,255,255,0.5) 1px solid;
	border-radius:4px;
	transition:all 0.5s ease;
}
.read-next-story.prev .post:before {
	content:"You Might Enjoy";
}
.read-next-story h2 {
	margin-top:1rem;
	color:#fff;
}
.read-next-story p {
	margin:0;
	color: rgba(255,255,255,0.8);
}
/* Special styles for posts with no cover images */
.read-next-story.no-cover {
	background:#f5f8fa;
}
.read-next-story.no-cover:before {
	display:none;
}
.read-next-story.no-cover .post:before {
	color:rgba(0,0,0,0.5);
	border-color:rgba(0,0,0,0.2);
}
.read-next-story.no-cover h2 {
	color:rgba(0,0,0,0.8);
}
.read-next-story.no-cover p {
	color: rgba(0,0,0,0.5);
}
/* if there are two posts without covers,put a border between them */
.read-next-story.no-cover+.read-next-story.no-cover {
	border-left:rgba(0,0,100,0.04) 1px solid;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing: border-box;
}
/* Correctly position site-footer when next to the .read-next container */
.read-next+.site-footer {
	position:relative;
	bottom:0;
	left:0;
	right:0;
	margin: 0;
}
/* ========================================================================== 8. Third Party Elements - Embeds from other services ========================================================================== */
/* Github */
.gist table {
	margin:0;
	font-size:1.4rem;
}
.gist td {
	line-height:1.4;
}
.gist .line-number {
	min-width: 25px;
}
/* Pastebin */
.content .embedPastebin {
	margin-bottom: 1.75em;
}
/* ========================================================================== 9. Pagination - Tools to let you flick between pages ========================================================================== */
/* The main wrapper for our pagination links */
.pagination {
	position:relative;
	width:80%;
	max-width:740px;
	padding:10px;
	margin:4rem auto;
	margin-bottom:7rem;
	font-family:Playfair Display;
	font-size:1.7rem;
	color:#111;
	text-align:center;
	font-style:italic;
	border-top:#EBF2F6 double medium;
	border-bottom:#EBF2F6 double medium;
}
.pagination a {
	color:#9EABB3;
	transition: all 0.2s ease;
}
/* Push the previous/next links out to the left/right */
.older-posts,.newer-posts {
	position:absolute;
	display:inline-block;
	padding:3px 15px;
	border:#bfc8cd 0px solid;
	text-decoration:none;
	border-radius:0;
	transition:border 0.3s ease;
	font-size:1.4rem;
}
.older-posts {
	right:0;
}
.page-number {
	display:inline-block;
	padding:2px 0;
	min-width:100px;
}
.newer-posts {
	left:0;
}
.older-posts:hover,.newer-posts:hover {
	color:#889093;
	border-color:#98a0a4;
}
.extra-pagination {
	display:none;
}
.extra-pagination .pagination {
	width: auto;
}
/* On page2+make all the headers smaller */
.archive-template .main-header {
	max-height: 65vh;
}
/* On page2+show extra pagination controls at the top of post list */
.archive-template .extra-pagination {
	display: block;
}
/* ========================================================================== 10. Footer - The bottom of every page ========================================================================== */
.site-footer {
	position:relative;
	margin:0rem 0 0 0;
	padding:4rem 15px;
	font-size:1.3rem;
	text-transform:uppercase;
	line-height:1.75em;
	color:rgba(255,255,255,0.5);
	background:#1d1d1d;
	letter-spacing:1px;
	font-family:Playfair Display;
}
.site-footer a {
	text-decoration:none;
	font-weight:bold;
	color:rgba(255,255,255,0.7);
}
.site-footer a:hover {
	color:#fff;
}
.poweredby {
	display:block;
	width:45%;
	float:right;
	text-align:right;
}
.copyright {
	display:block;
	width:45%;
	float: left;
}

.entries-nav-blog{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: "20px";
	flex-direction: row;
}

.sessionbutton{
	margin-bottom: 10px;
}

.socialbuttons{
	margin-bottom: 0px;
}

/* ========================================================================== 11. Media Queries - Smaller than 900px ========================================================================== */
@media only screen and (max-width:900px) {
	.sessionbutton{
		margin-bottom: 30px;
	}
	.socialbuttons{
		margin-bottom: 30px;
	}
	.entries-nav-blog{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		
		flex-direction: column;
	}

	blockquote {
		margin-left:0;
	}
	.main-header {
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
		height:auto;
		min-height:100%;
		height:100vh;
		padding:0;
	}
  .main-header .inner {
    margin-top: 0;
}
	.scroll-down,.home-template .main-header:after {
		display:none;
	}
	.archive-template .main-header {
		min-height:180px;
		padding:10% 0;
	}
	.blog-logo img {
		padding:0 0 0 10px;
		margin-top:-54px;
	}
	.page-title {
		font-size:4rem;
		letter-spacing:-1px;
	}
	.page-description {
		font-size:1.8rem;
		line-height:1.5em;
	}
	.post {
		font-size:0.95em
	}
	body:not(.post-template) .post-title {
		font-size:3.2rem;
	}
	hr {
		margin:2.4em 0;
	}
	ol,ul {
		padding-left:2em;
	}
	h1 {
		font-size:4.5rem;
		text-indent:-2px;
	}
	h2 {
		font-size:3.6rem;
	}
	h3 {
		font-size:3.1rem;
	}
	h4 {
		font-size:2.5rem;
	}
	h5 {
		font-size:2.2rem;
	}
	h6 {
		font-size:1.8rem;
	}
	.author-profile {
		padding-bottom:4rem;
	}
	.author-profile .author-bio {
		font-size:1.6rem;
	}
	.author-meta span {
		display:block;
		margin:1.5rem 0;
	}
	.author-profile .author-meta span {
		font-size:1.6rem;
	}

	.tag-head.main-header,.author-head.main-header, .post-head.main-header, .no-cover.post-head.main-header {
		height:auto;
    min-height: 40vh;
	}
  .post-template .content {
    width: 95%;
    max-width: 95%;
    padding: 40px 20px;
  }
	.no-cover.author-head.main-header {
		padding:0;
	}
	.read-next {
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-webkit-flex-direction:column;
		-ms-flex-direction:column;
		flex-direction:column;
		margin-top:4rem;
	}
	.read-next p {
		display:none;
	}
	.read-next-story.no-cover+.read-next-story.no-cover {
		border-top:rgba(0,0,100,0.06) 1px solid;
		border-left: none;
	}
}
/* ========================================================================== 12. Media Queries - Smaller than 500px ========================================================================== */
@media only screen and (max-width:500px) {
	.main-header {
		height:60vh;
		margin-bottom:0;
	}
	.no-cover.main-header {
		height:60vh;
	}
	.archive-template .main-header {
		max-height:20vh;
		min-height:160px;
		padding:0% 0;
	}
	.main-nav {
		padding:5px;
		margin-bottom:0rem;
	}
	.blog-logo {
		padding:0px;
	}
	.blog-logo img {
	}
	.menu-button {
		padding:0 5px;
		border-radius:0;
		border-width:0;
		color:#2e2e2e;
		background:transparent;
	}
	.menu-button:hover {
		color:#2e2e2e;
		border-color:transparent;
		background:none;
	}
	body.nav-opened .menu-button {
		background:none;
		border:transparent;
	}
	.main-nav.overlay a:hover {
		color:#fff;
		border-color:transparent;
		background:transparent;
	}
	.no-cover .main-nav.overlay {
		background:none;
	}
	.no-cover .main-nav.overlay .menu-button {
		border:none;
	}
	.main-nav.overlay .menu-button {
		border-color:transparent;
	}
	.nav-title {
		top:25px;
	}
	.nav-close {
		position:absolute;
		top:18px;
	}
	.nav ul {
		padding:60px 9% 5%;
	}
	.inner,.pagination {
		width:auto;
		margin:2rem auto;
	}
	.post {
		width:auto;
		margin-top:2rem;
		margin-bottom:2rem;
		margin-left:16px;
		margin-right:16px;
		padding-bottom:2rem;
		line-height:1.65em;
	}
	.post-date {
		display:none;
	}
	.post-template .post-header {
		margin-bottom:2rem;
	}
	.post-template .post-date {
		display:inline-block;
	}
	hr {
		margin:1.75em 0;
	}
	p,ul,ol,dl {
		font-size:0.95em;
		margin:0 0 2.5rem 0;
	}
	.page-title {
		font-size:3rem;
	}
	.post-excerpt p {
		font-size:0.85em;
	}
	.page-description {
		font-size:1.6rem;
	}
	h1,h2,h3,h4,h5,h6 {
		margin:0 0 0.3em 0;
	}
	h1 {
		font-size:2.8rem;
		letter-spacing:-1px;
	}
	h2 {
		font-size:2.4rem;
		letter-spacing:0;
	}
	h3 {
		font-size:2.1rem;
	}
	h4 {
		font-size:1.9rem;
	}
	h5 {
		font-size:1.8rem;
	}
	h6 {
		font-size:1.8rem;
	}
	body:not(.post-template) .post-title {
		font-size:2.5rem;
	}
	.post-template .site-footer {
		margin-top: 0;
	}
	.post-content img {
		padding:0;
		width:calc(100%+32px);
		/* expand with to image+margins */
		min-width:0;
		max-width:112%;
		/* fallback when calc doesn't work */
	}
	.post-meta {
		font-size:1.3rem;
		margin-top:1rem;
	}
	.post-footer {
		padding:5rem 0 3rem 0;
		text-align:center;
	}
	.post-footer .author {
		margin:0 0 2rem 0;
		padding:0 0 1.6rem 0;
		border-bottom:#EBF2F6 1px dashed;
	}
	.post-footer .share {
		position:static;
		width:auto;
	}
	.post-footer .share a {
		margin:1.4rem 0.8rem 0 0.8rem;
	}
	.author-meta li {
		float:none;
		margin:0;
		line-height:1.75em;
	}
	.author-meta li:before {
		display:none;
	}
	.older-posts,.newer-posts {
		position:static;
		margin:10px 0;
	}
	.page-number {
		display:block;
	}
	.site-footer {
		margin-top:3rem;
	}
	.author-profile {
		padding-bottom:2rem;
	}
	.tag-head.main-header,.post-head.main-header,.author-head.main-header {
		min-height:30vh;
	}
	.main-header .inner {
		margin-top:0;
	}
	.author-profile .author-image {
		margin-top:-70px;
	}
	.author-profile .author-meta span {
		font-size:1.4rem;
	}
	.archive-template .main-header .page-description {
		display:none;
	}
	.read-next {
		margin-top:2rem;
		margin-bottom:-37px;
	}
	.read-next .post {
		width: 100%;
	}
}
/* ========================================================================== 13. Animations ========================================================================== */
/* Used to fade in title/desc on the home page */
@-webkit-keyframes fade-in-down {
	0% {
		opacity:0;
		-webkit-transform:translateY(-10px);
		transform:translateY(-10px);
	}
	100% {
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0);
	}
}
@keyframes fade-in-down {
	0% {
		opacity:0;
		-webkit-transform:translateY(-10px);
		transform:translateY(-10px);
	}
	100% {
		opacity:1;
		-webkit-transform:translateY(0);
		transform: translateY(0);
	}
}
/* Used to bounce .scroll-down on home page */
@-webkit-keyframes bounce {
	0%,10%,25%,40%,50% {
		-webkit-transform:translateY(0) rotate(-90deg);
		transform:translateY(0) rotate(-90deg);
	}
	20% {
		-webkit-transform:translateY(-10px) rotate(-90deg);
		transform:translateY(-10px) rotate(-90deg);
	}
	30% {
		-webkit-transform:translateY(-5px) rotate(-90deg);
		transform:translateY(-5px) rotate(-90deg);
	}
}
@keyframes bounce {
	0%,20%,50%,80%,100% {
		-webkit-transform:translateY(0) rotate(-90deg);
		transform:translateY(0) rotate(-90deg);
	}
	40% {
		-webkit-transform:translateY(-10px) rotate(-90deg);
		transform:translateY(-10px) rotate(-90deg);
	}
	60% {
		-webkit-transform:translateY(-5px) rotate(-90deg);
		transform: translateY(-5px) rotate(-90deg);
	}
}
/* ========================================================================== End of file. Animations should be the last thing here. Do not add stuff below this point,or it will probably fuck everything up. ========================================================================== */
.btn-more {
	padding:3px;
	border:1px solid rgba(255,255,255,0.6);
	display:table;
	margin:0 auto;
	margin-top:20px;
}
.btn-more span {
	border:1px solid rgba(255,255,255,0.6);
	padding:10px 30px;
	color:#FFF;
	display:block;
	font-size:16px;
	letter-spacing:2px;
	text-transform:uppercase;
}
a.btn-more {
	text-decoration:none;
}
.grid {
	margin-left:2.5%;
	margin-right:2%;
}
.grid-item {
	width:32.00%;
}
.entry-title-divider {
	position:relative;
	display:inline-block;
	vertical-align:top;
	margin:0 auto;
	margin-top:15px;
	margin-bottom:7px;
	height:5px;
}
.entry-title-divider:before,.entry-title-divider:after {
	content:'';
	position:absolute;
	top:2px;
	width:40px;
	height:1px;
	background-color:#f4f4f4;
}
.entry-title-divider:before {
	left:-48px;
}
.entry-title-divider:after {
	right:-48px;
}
.entry-title-divider span {
	display:inline-block;
	vertical-align:top;
	width:5px;
	height:5px;
	margin:0 3px;
	-webkit-transform:rotate(45deg);
	transform:rotate(45deg);
}
.entry-title-divider span {
	background-color:#fff;
}
.text-center {
	text-align:center;
}
.grid-item {
}
.grid-item img {
	transition:transform 0.5s linear;
}
.grid-item img:hover {
	transform:scale(1.2);
	animation:10s ease 0s normal none infinite scale;
}
.back-top {
	position:absolute;
	z-index:5;
	top:0px;
	left:50%;
	margin-left:-12.5px;
	display:block;
	width:25px;
	height:25px;
	background:url(./assets/img/top.png) no-repeat center center;
}
footer.site-footer:after {
	bottom:100%;
	left:50%;
	border:solid transparent;
	content:" ";
	height:0;
	width:0;
	position:absolute;
	pointer-events:none;
	border-color:rgba(17,17,17,0);
	border-bottom-color:#1D1D1D;
	border-width:30px;
	margin-left:-30px;
}
.imgshadow {
	position:absolute;
	top:0;
	left:0;
	margin-left:0;
	z-index:5;
	zoom:1;
	width:100%;
}
.wrapshadow {
	position:absolute;
	top:0;
	left:-34px;
	max-width:200%;
	zoom:1;
	width:1214px;
}
body.nav-opened {
	padding-top:0;
}
.main-nav ul {
	list-style:none;
	margin:5px 0;
	text-align:center;
	padding-left:0;
}
.main-nav ul li {
	display:inline-block;
}
.main-nav ul li a {
	padding:0 10px;
}
@font-face {
	font-family:'Mono Social Icons Font';
	src:url('./assets/fonts/MonoSocialIconsFont-1.10.eot');
	src:url('./assets/fonts/MonoSocialIconsFont-1.10.eot?#iefix') format('embedded-opentype'),url('./assets/fonts/MonoSocialIconsFont-1.10.woff') format('woff'),url('./assets/fonts/MonoSocialIconsFont-1.10.ttf') format('truetype'),url('./assets/fonts/MonoSocialIconsFont-1.10.svg#MonoSocialIconsFont') format('svg');
	src:url('./assets/fonts/MonoSocialIconsFont-1.10.ttf') format('truetype');
	font-weight:normal;
	font-style:normal;
}
.symbol,a.symbol:before {
	font-family:'Mono Social Icons Font';
	-webkit-text-rendering:optimizeLegibility;
	-moz-text-rendering:optimizeLegibility;
	-ms-text-rendering:optimizeLegibility;
	-o-text-rendering:optimizeLegibility;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-font-smoothing:antialiased;
	-ms-font-smoothing:antialiased;
	-o-font-smoothing:antialiased;
	font-smoothing:antialiased;
}
a.symbol:before {
	content:attr(title);
	margin-right:0.3em;
	font-size:130%;
}
a.symbol {
	background:#9f9f9f;
	padding:7px 5px 3px 5px;
	color:white;
	text-decoration:none;
}
span.socialheader {
	float:right;
}
.socialheader span {
	font-size:1.7em;
	display:inline-block;
	line-height:43px;
	margin:0 3px;
}
div#preloader {
	position:fixed;
	left:0;
	top:0;
	z-index:999;
	width:100%;
	height:100%;
	overflow:visible;
	background:#333;
}
@media only screen and (max-width:1220px) {
	.grid-item {
		width:48%;
	}
	.wrapshadow {
		display:none;
	}
}
@media only screen and (max-width:930px) {
}
@media only screen and (max-width:650px) {
	.grid-item {
		width:100%;
	}
}
@media only screen and (max-width:500px) {
	.content,.post-template .content,.content,.post-template .content {
		margin-top:-30px;
	}
	.post-template .content {
		padding:20px 0;
	}
}
.slicknav_menu {
	display: none;
}
@media screen and (max-width:900px) {
	/* #menu is the original menu */
	#menu {
			display:none;
	}
	.site-wrapper {
		margin-top:0;
	}
	.slicknav_menu {
			display:block;
	}
	.main-nav.overlay {
		position:relative;
		height:3px;
	}
	span.socialheader {
		float:none;
		margin-bottom:50px;
		display: block;
	}
}
/*! * SlickNav Responsive Mobile Menu v1.0.5.5 * (c) 2015 Josh Cope * licensed under MIT */
.slicknav_btn {
	position:relative;
	display:block;
	vertical-align:middle;
	float:right;
	padding:0.438em 0.625em 0.438em 0.625em;
	line-height:1.125em;
	cursor:pointer;
}
.slicknav_btn .slicknav_icon-bar+.slicknav_icon-bar {
	margin-top:0.188em;
}
.slicknav_menu {
	*zoom:1;
}
.slicknav_menu .slicknav_menutxt {
	display:block;
	line-height:1.188em;
	float:left;
}
.slicknav_menu .slicknav_icon {
	float:left;
	width:1.125em;
	height:0.875em;
	margin:0.188em 0 0 0.438em;
}
.slicknav_menu .slicknav_icon:before {
	background:transparent;
	width:1.125em;
	height:0.875em;
	display:block;
	content:"";
	position:absolute;
}
.slicknav_menu .slicknav_no-text {
	margin:0;
}
.slicknav_menu .slicknav_icon-bar {
	display:block;
	width:1.125em;
	height:0.125em;
	-webkit-border-radius:1px;
	-moz-border-radius:1px;
	border-radius:1px;
	-webkit-box-shadow:0 1px 0 rgba(0,0,0,0.25);
	-moz-box-shadow:0 1px 0 rgba(0,0,0,0.25);
	box-shadow:0 1px 0 rgba(0,0,0,0.25);
}
.slicknav_menu:before {
	content:" ";
	display:table;
}
.slicknav_menu:after {
	content:" ";
	display:table;
	clear:both;
}
.slicknav_nav {
	clear:both;
}
.slicknav_nav ul {
	display:block;
}
.slicknav_nav li {
	display:block;
}
.slicknav_nav .slicknav_arrow {
	font-size:0.8em;
	margin:0 0 0 0.4em;
}
.slicknav_nav .slicknav_item {
	cursor:pointer;
}
.slicknav_nav .slicknav_item a {
	display:inline;
}
.slicknav_nav .slicknav_row {
	display:block;
}
.slicknav_nav a {
	display:inline-block;
}
.slicknav_nav .slicknav_parent-link a {
	display:inline;
}
.slicknav_brand {
	float:left;
}
.slicknav_menu {
	font-size:16px;
	box-sizing:border-box;
	background:#313232;
	padding:5px;
	right:0;
	z-index:99;
}
.slicknav_menu * {
	box-sizing:border-box;
}
.slicknav_menu .slicknav_menutxt {
	color:#fff;
	font-weight:bold;
	text-shadow:0 1px 3px #000;
}
.slicknav_menu .slicknav_icon-bar {
	background-color:#fff;
}
.slicknav_btn {
	margin:5px 5px 6px;
	text-decoration:none;
	text-shadow:0 1px 1px rgba(255,255,255,0.75);
	-webkit-border-radius:4px;
	-moz-border-radius:4px;
	border-radius:4px;
	background-color:#222;
}
.slicknav_nav {
	color:#fff;
	margin:0;
	padding:0;
	font-size:0.875em;
	list-style:none;
	overflow:hidden;
}
.slicknav_nav ul {
	list-style:none;
	overflow:hidden;
	padding:0;
	margin:0 0 0 20px;
}
.slicknav_nav .slicknav_row {
	padding:5px 10px;
	margin:2px 5px;
}
.slicknav_nav .slicknav_row:hover {
	-webkit-border-radius:6px;
	-moz-border-radius:6px;
	border-radius:6px;
	background:#ccc;
	color:#fff;
}
.slicknav_nav a {
	padding:5px 10px;
	margin:2px 5px;
	text-decoration:none;
	color:#fff;
}
.slicknav_nav a:hover {
	-webkit-border-radius:6px;
	-moz-border-radius:6px;
	border-radius:6px;
	background:#ccc;
	color:#222;
}
.slicknav_nav .slicknav_txtnode {
	margin-left:15px;
}
.slicknav_nav .slicknav_item a {
	padding:0;
	margin:0;
}
.slicknav_nav .slicknav_parent-link a {
	padding:0;
	margin:0;
}
.slicknav_brand {
	color:#fff;
	font-size:18px;
	line-height:30px;
	padding:7px 12px;
	height: 44px;
}